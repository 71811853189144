<div class="avatar-container">
    <div class="image-container" *ngIf="file; else placeholder">
        <img [src]="file">
    </div>

    <ng-template #placeholder>
        <div class="image-container avatar-placeholder">
            <img disableOptimizedSrcset [fill]="true" [loaderParams]="{'preset': 'avatar-small'}" [ngSrc]="user()|avatarPath" role="none" [loading]="'lazy'">
        </div>
    </ng-template>

    @if (uploadEnabled()) {
    <label for="avatar-input-file">
        <div class="icon-wrapper">
            <input type="file" id="avatar-input-file" accept="image/*" (change)="onFileChange($event)" />
            <mat-icon class="icon" [svgIcon]="'heroicons_outline:pencil'"/>
        </div>
    </label>
    }

</div>
