import { Component, OnInit, input, output, inject, ChangeDetectorRef} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ImageCropperComponent } from './image-cropper/image-cropper.component';
import { NgClass, NgIf, NgOptimizedImage, NgStyle } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AuthorInterface, User } from '../../../user/user';
import { AvatarPathPipe } from '../../../user/pipes/avatar-path.pipe';
import {UserService} from "../../../user/user.service";
import {AuthStore} from "../../../core/auth/auth.store";
import {NotificationService} from "../../../core/notification/notification.service";
// https://medium.com/@ruslan.ap2/how-to-create-an-avatar-uploader-with-angular-9fe89ff4fe60
@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  standalone: true,
  imports: [
    MatDialogModule, NgIf, NgStyle, MatIconModule, NgClass, MatSnackBarModule, NgOptimizedImage,AvatarPathPipe
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: AvatarComponent
    }
  ]
})
export class AvatarComponent implements OnInit, ControlValueAccessor {
  uploadEnabled = input<boolean>(false);

  private service = inject(UserService)
  private authStore = inject(AuthStore)
  private _cdk = inject(ChangeDetectorRef);
  private notificationService = inject(NotificationService)

  file: string = '';

  user = input<User|AuthorInterface|null>()

  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {}

  writeValue(_file: string): void {
    this.file = _file;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onChange = output<File|null>({ alias: 'onChange' });

  onTouched = () => {};

  disabled: boolean = false;

  onFileChange(event: any) {
    const files = event.target.files as FileList;

    if (files.length > 0) {
      const originalFile = files[0];
      const fileUrl = URL.createObjectURL(originalFile);
      this.resetInput();
      this.openAvatarEditor(fileUrl)
      .subscribe(
        (result) => {
          this.file = result;
          const imageToUpload = this.urltoFile(result, originalFile.name, originalFile.type);
          this.uploadImage(imageToUpload);
          this._cdk.detectChanges();
        }
      )
    }
  }

  openAvatarEditor(image: string): Observable<any> {
    const dialogRef = this.dialog.open(ImageCropperComponent, {
      maxWidth: '80vw',
      maxHeight: '90vh',
      panelClass: 'image-cropper-dialog',
      data: image,
    });

    return dialogRef.afterClosed();
  }

  resetInput(){
    const input = document.getElementById('avatar-input-file') as HTMLInputElement;

    if(input){
      this.file = "";
      input.value = this.file;
      this.onChange.emit(null)
    }
  }

  urltoFile(url: string, filename: string, mimeType: string): File {
      const arr = url.split(',')
      const bstr = atob(arr[arr.length - 1])
      let n = bstr.length
      let u8arr = new Uint8Array(n);

      while(n--){
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mimeType });
  }

  uploadImage($event: File|null) {
    if (!$event) {
      return
    }

    this.service.changeAvatar(this.user()!!, $event).subscribe({
      next: () => {
        this.authStore.getIdentity().then(() => {
          this.notificationService.success("Awatar został zmieniony")
        })
      },
      error: (error) => {
        this.notificationService.success("Wystąpił błąd podczas zmiany awataru użytkowika")
        console.error('Error changing avatar', error)
      }
    })
  }
}
