<div class="container">
  <div class="crop-container">
    <img id="image" [src]="sanitizedUrl" alt="new avatar holder">
  </div>

  <div class="actions">
    <div class="cropper-options">

      <div>
        <button class="btn" (click)="cropper.setDragMode('move')">
          <mat-icon [svgIcon]="'mat_outline:pan_tool'"></mat-icon>
        </button>

        <button class="btn" (click)="cropper.setDragMode('crop')">
          <mat-icon [svgIcon]="'mat_outline:crop'"></mat-icon>
        </button>
      </div>

      <div class="no-mobile">
        <button class="btn" (click)="cropper.zoom(-0.1)">
          <mat-icon [svgIcon]="'heroicons_outline:magnifying-glass-minus'"></mat-icon>
        </button>

        <button class="btn" (click)="cropper.zoom(0.1)">
          <mat-icon [svgIcon]="'heroicons_outline:magnifying-glass-plus'"></mat-icon>
        </button>
      </div>

      <div class="no-mobile">
        <button class="btn" (click)="cropper.move(-10, 0)">
          <mat-icon [svgIcon]="'heroicons_outline:arrow-left'"></mat-icon>
        </button>
        <button class="btn" (click)="cropper.move(10, 0)">
          <mat-icon [svgIcon]="'heroicons_outline:arrow-right'"></mat-icon>
        </button>
        <button class="btn" (click)="cropper.move(0, 10)">
          <mat-icon [svgIcon]="'heroicons_outline:arrow-up'"></mat-icon>
        </button>
        <button class="btn" (click)="cropper.move(0, -10)">
          <mat-icon [svgIcon]="'heroicons_outline:arrow-down'"></mat-icon>
        </button>
      </div>

      <div>
        <button class="btn" (click)="cropper.reset()">
          <mat-icon [svgIcon]="'mat_outline:restart_alt'"></mat-icon>
        </button>
      </div>

    </div>
    <div class="dialog-actions">
      <button type="button" class="btn btn-primary" (click)="crop()">
        Zapisz
      </button>

      <button class="btn" (click)="reset()">
        Anuluj
      </button>
    </div>
  </div>
</div>
