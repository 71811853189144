import {Component, input} from '@angular/core';
import {User} from "../../user";
import {DisplayNamePipe} from "../../pipes/display-name.pipe";
import {AvatarComponent} from "../../../ui-kit/components/avatar/avatar.component";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-profile-settings-header',
  standalone: true,
  imports: [
    DisplayNamePipe,
    AvatarComponent,
    NgIf
  ],
  templateUrl: './profile-settings-header.component.html',
  styleUrl: './profile-settings-header.component.scss'
})
export class ProfileSettingsHeaderComponent {
  readonly user = input<User>()
  showActions = input<boolean>(true)
  uploadEnabled = input<boolean>(true)
}
